<template>
  <div :class="containerClass" @click="onWrapperClick">
  <div class="layout-main">
  <router-view />
  </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      layoutMode: 'static',
    }
  },
  watch: {
    $route() {
      this.$toast.removeAllGroups();
    }
  },
  methods: {
    isDesktop() {
      return window.innerWidth > 1024;
    },
    isSidebarVisible() {
      return false
    },
  },
  computed: {
    containerClass() {
      return [
        'layout-wrapper',
        {
          'layout-overlay': true,
          'layout-static-sidebar-inactive': true,
          'p-input-filled': true
        }
      ];
    }
  },
  components: {}
}
</script>

<style lang="scss">
@import './App.scss';
</style>
