import axios from 'axios'

const KEEP_ALIVE_INTERVAL = 300000  // 5 minutes
const LOCAL_STORAGE_KEYS = {
  authSessionId: 'flexi.session.id',
  companyUrl: 'flexi.session.companyurl'
}

const cparam = process.env.VUE_APP_CLEAR_URL_QUERY
const CLEAR_URL_QUERY = cparam === 'false' ? false : !!cparam
const ABRA_FLEXI_SERVER_HOST = ''
const ABRA_FLEXI_COMPANY = process.env.VUE_APP_ABRA_FLEXI_COMPANY
let ABRA_FLEXI_COMPANY_REWRITE = localStorage.getItem(LOCAL_STORAGE_KEYS.companyUrl) || null

let running = null
let error = null

export async function init(route, router) {
  if (this.running) {
    return
  }

  if (route.query && route.query.auth) {
    localStorage.setItem(LOCAL_STORAGE_KEYS.authSessionId, route.query.auth)
    if (CLEAR_URL_QUERY) {
      let query = Object.assign({}, route.query)
      delete query.auth
      router.replace({ query, path: route.path })
    }
  }

  if (route.query && route.query.company) {
    ABRA_FLEXI_COMPANY_REWRITE = route.query.company
    if (ABRA_FLEXI_COMPANY_REWRITE) {
      const arr = ABRA_FLEXI_COMPANY_REWRITE.split('/')
      ABRA_FLEXI_COMPANY_REWRITE = arr[arr.length - 1]
      localStorage.setItem(LOCAL_STORAGE_KEYS.companyUrl, ABRA_FLEXI_COMPANY_REWRITE)
    }
    if (CLEAR_URL_QUERY) {
      let query = Object.assign({}, route.query)
      delete query.company
      router.replace({ query, path: route.path })
    }
  }

  console.log(ABRA_FLEXI_COMPANY_REWRITE + ' ----')

  run()
}

export async function check() {
  await keepAliveTick()
}

async function keepAliveTick() {
  try {
    const resp = await APIGet('/login-logout/session-keep-alive.json')
  } catch (err) {
    console.log(err)
    error = true
    stop()
    throw new Error('Invalid session')
  }
}

export function run() {
  if (running) {
    return
  }

  running = setInterval(keepAliveTick, KEEP_ALIVE_INTERVAL)
}

export function stop() {
  if (running) {
    clearInterval(running)
    running = null
  }
}

async function APIGet(path, params) {
  let rpath = `/api/c/${ABRA_FLEXI_COMPANY_REWRITE || ABRA_FLEXI_COMPANY}${path}`
  if (path.startsWith('/login-logout') || path.startsWith('/status')) {
    rpath = `/api${path}`
  }
  const url = ABRA_FLEXI_SERVER_HOST + rpath
  const opts = {}
  if (params) {
    opts.params = params
  } else {
    opts.params = {}
  }
  if (!opts.params.limit) {
    opts.params.limit = 0
  }

  opts.headers = {
    'X-authSessionId': localStorage.getItem(LOCAL_STORAGE_KEYS.authSessionId)
  }

  const resp = await axios.get(url, opts)

  if (path.startsWith('/login-logout')) {
    return resp.data
  }
  return resp.data.winstrom
}

async function APIPost(path, params, data, encoding) {
  let rpath = `/api/c/${ABRA_FLEXI_COMPANY_REWRITE || ABRA_FLEXI_COMPANY}${path}`
  if (path.startsWith('/login-logout') || path.startsWith('/status')) {
    rpath = `/api${path}`
  }
  const url = ABRA_FLEXI_SERVER_HOST + rpath
  const opts = {}
  if (params) {
    opts.params = params
  }
  opts.headers = {
    'X-authSessionId': localStorage.getItem(LOCAL_STORAGE_KEYS.authSessionId)
  }

  const msg = {
    winstrom: data
  }
  msg.winstrom['@version'] = '1.0'

  const resp = await axios.post(url, msg, opts)

  if (path.startsWith('/login-logout')) {
    return resp.data
  }
  return resp.data.winstrom
}

export const api = {
  get: APIGet,
  post: APIPost
}

export default {
  init,
  check,
  run,
  stop,
  api
}
