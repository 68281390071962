import { createRouter, createWebHashHistory } from 'vue-router';
import session from './service/flexiAPI/flexiSession.js'

const routes = [
    {
        path: '/import-pricelist',
        name: 'importpricelist',
        component: () => import('./pages/ImportPricelist.vue'),
        meta: {
          sessionRequired: true
        }
    },
    {
        path: '/import-partner-codes',
        name: 'importpartnercodes',
        component: () => import('./pages/ImportPartnerCodes.vue'),
        meta: {
          sessionRequired: true
        }
    },
    {
        path: '/import-offer-received',
        name: 'importofferreceived',
        component: () => import('./pages/ImportOfferReceived.vue'),
        meta: {
          sessionRequired: true
        }
    },
    {
        path: '/export-pricelist',
        name: 'exportpricelist',
        component: () => import('./pages/ExportPricelist.vue'),
        meta: {
          sessionRequired: true
        }
    },
    {
        path: '/not-found',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue'),
    },
    {
        path: '/missing-session',
        name: 'missingsession',
        component: () => import('./pages/MissingSession.vue'),
    },
    {
        path: '/:pathMatch(.*)*',
        redirect: '/not-found',
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach(async (to, from, next) => {
  await session.init(to, router)

  if (to.matched.some(record => record.meta.sessionRequired)) {
    try {
      await session.check()
    } catch (e) {
      console.log(e)
      next({ name: 'missingsession' })
      return
    }
  }
  next()
})

export default router;
